import axios from 'axios';
import * as firebaseService from 'services/firebase';

export const API_URL = 'https://us-central1-mapbox-search-example.cloudfunctions.net/routingV2';
// export const API_URL = process.env.REACT_APP_DEV ? 'http://localhost:5001/mapbox-search-example/us-central1/routingV2' : 'https://us-central1-mapbox-search-example.cloudfunctions.net/routingV2';

export const makePayment = async (paymentMethod, name, email, coupon, address, city, state, zip, paymentAmount) => {
  const { data } = await axios.post(`${API_URL}/pro`, {
    paymentMethod, name, email, address, city, state, zip, paymentAmount, ...(coupon) && { coupon },
  });
  return data;
};

export const subscribeToRevenueCat = async (subscriptionId, regionId, displayName) => {
  const token = await firebaseService.getAuthToken();

  const { data } = await axios.post(`${API_URL}/revenuecat/subscribe`, {
    subscriptionId, attributes: { regionId, displayName },
  }, { headers: { Authorization: `Bearer ${token}` } });
  return data;
};

export const updateRevenueCatEntitlements = async (purchaseId, displayName, redemptionCode, duration) => {
  const token = await firebaseService.getAuthToken();
  const { data } = await axios.post(`${API_URL}/revenuecat/entitlement`, {
    purchaseId, attributes: { displayName }, redemptionCode, duration,
  }, { headers: { Authorization: `Bearer ${token}` } });
  return data;
};

export const updateRevenueCatAttributes = async (attributes) => {
  const token = await firebaseService.getAuthToken();

  const { data } = await axios.post(`${API_URL}/revenuecat/update`, {
    ...attributes,
  }, { headers: { Authorization: `Bearer ${token}` } });
  return data;
};

export const updatePayment = async (paymentMethod, email) => {
  const { data } = await axios.post(`${API_URL}/update-payment`, {
    paymentMethod, email,
  });
  return data;
};

export const cancelSubscription = async (email, subscriptionId) => {
  if (!email && !subscriptionId) {
    const token = await firebaseService.getAuthToken();
    const emailAddress = firebaseService.getUserEmail();
    const uid = firebaseService.getUserId();

    const { data } = await axios.post(`${API_URL}/cancel`, { email: emailAddress, uid }, { headers: { Authorization: `Bearer ${token}` } });
    return data;
  } else if (subscriptionId) {
    const { data } = await axios.post(`${API_URL}/cancel`, { subscriptionId });
    return data;
  }
};

export const retrieveCoupon = async (code) => {
  const { data } = await axios.post(`${API_URL}/coupon`, { coupon: code });
  return data;
};

export const retrievePrice = async () => {
  const { data } = await axios.get(`${API_URL}/price`);
  return data;
};

export const getStatusByEmail = async (email) => {
  const { data } = await axios.get(`${API_URL}/revenuecat/getStatus?email=${encodeURIComponent(email)}`);
  return data.status;
};
